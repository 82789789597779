//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

//* Add the Web App's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA4KpyEmko0Amf33Ol9DJ1hujJ1W_lHblY",
  authDomain: "rapotap-d3f86.firebaseapp.com",
  projectId: "rapotap-d3f86",
  storageBucket: "rapotap-d3f86.firebasestorage.app",
  messagingSenderId: "459269035776",
  appId: "1:459269035776:web:6b5fa941fa3f27ec3885e5",
};

//* Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

export { auth };
export default firebase_app;
